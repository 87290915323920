const mutations = {
  set: (state, res) => {
    for (const key in res) {
      if (Object.hasOwnProperty.call(res, key)) {
        state[key] = res[key]
      }
    }
  },
  setMember: (state, res) => {
    for (const key in res) {
      if (Object.hasOwnProperty.call(res, key)) {
        state.member[key] = res[key]
      }
    }
  },
  setPanelNav: (state, res) => {
    for (const key in res) {
      if (Object.hasOwnProperty.call(res, key)) {
        state.panelNav[key] = res[key]
      }
    }
  }
}

export default mutations
