module.exports = {
  // common
  ok: '確定',
  cancel: '取消',
  language: {
    en_US: 'English',
    ja_JP: '日本語',
    ru_RU: 'Pусский',
    zh_CN: '简体中文',
    zh_HK: '繁體中文（香港）',
    zh_TW: '繁體中文（台灣）'
  },

  // router
  router: {
    timeout: '登陸超時'
  },

  // request
  request: {
    expired: '登陸過期',
    quit: 'Bot 已不在群組中',
    notAdmin: '非法登陸，您不是群組管理員',
    maintain: '系統維護中',
    requestFailed: '伺服器請求失敗，請稍後再試'
  },

  // views
  autoLogin: {
    tips: '認證中{dot}',

    autoLoginApi: {
      paramError: '引數異常',
      linkExpired: '認證連結已過期'
    }
  },
  login: {
    inputEmail: '請輸入郵箱',
    inputPass: '請輸入密碼',
    inputCaptcha: '請輸入驗證碼',
    captchaFailed: '獲取失敗',
    register: '註冊賬號',
    update: '修改資訊',
    login: '登 陸',
    logging: '登陸中...',

    captchaApi: {
      captchaOverTimes: '今日驗證碼獲取次數已達上限，請明日再試'
    },
    loginApi: {
      emailCanNotEmpty: '郵箱不能為空',
      passCanNotEmpty: '密碼不能為空',
      captchaCanNotEmpty: '驗證碼不能為空',

      paramError: '引數異常',
      mailError: '郵箱格式錯誤',
      captchaError: '驗證碼錯誤',
      loginFailedOverTimes: '賬號或密碼錯誤次數過多，請明日再試',
      mailOrPassError: '郵箱或密碼錯誤',
      busy: '伺服器繁忙，請稍後再試'
    },

    win: {
      register: '<p>請私聊 Bot 傳送</p><strong style="color: #ff3333;">!register</strong> 獲取註冊連結',
      update: '<p>請私聊 Bot 傳送</p><strong style="color: #ff3333;">!update-pass</strong> 獲取重置密碼的連結<br><strong style="color: #ff3333;">!update-mail</strong> 獲取更換郵箱的連結'
    }
  },
  register: {
    title: '賬號註冊',
    inputEmail: '請輸入郵箱',
    inputCaptcha: '請輸入驗證碼',
    inputPass: '請輸入密碼',
    inputConfirmPass: '請再次輸入密碼',
    register: '注 冊',
    registering: '註冊中...',

    captchaText: '獲取驗證碼',

    mailApi: {
      mailLimit: '郵箱地址錯誤，僅支援 qq、163、126、yeah、sina、gmail、icloud、foxmail、outlook',

      paramError: '引數異常',
      linkExpired: '連結超時，請重新獲取',
      mailExist: '郵箱已被佔用',
      overTimes: '當日傳送已達 10 條，請明日再試',
      busy: '郵件傳送過於頻繁',
      failed: '郵件傳送失敗',
      success: '郵件已傳送，請注意查收'
    },
    registerApi: {
      emailCanNotEmpty: '郵箱不能為空',
      captchaCanNotEmpty: '驗證碼不能為空',
      passCanNotEmpty: '密碼不能為空',
      confirmPassCanNotEmpty: '二次重複密碼不能為空',
      passAndConfirmPassNotSame: '兩次輸入的密碼不一致',

      paramError: '引數異常',
      linkExpired: '連結超時，請重新獲取',
      registered: '您已註冊過賬號',
      captchaError: '驗證碼錯誤',
      captchaGetMailAndConfirmMailNotSame: '獲取驗證碼的郵箱與實際註冊郵箱不一致',
      mailExist: '郵箱已被佔用',
      passWeak: '密碼不符合規範',
      success: '註冊成功',
      failed: '註冊失敗'
    }
  },
  registerUpdate: {
    titlePass: '重置密碼',
    titleMail: '更換郵箱',
    inputEmail: '請輸入新郵箱',
    inputCaptcha: '請輸入驗證碼',
    inputPass: '請輸入密碼',
    inputConfirmPass: '請再次輸入密碼',
    update: '提 交',
    updating: '提交中...',

    captchaText: '獲取驗證碼',

    mailApi: {
      mailLimit: '郵箱地址錯誤，僅支援 qq、163、126、yeah、sina、gmail、icloud、foxmail、outlook',

      paramError: '引數異常',
      linkExpired: '連結超時，請重新獲取',
      mailExist: '郵箱已被佔用',
      overTimes: '當日傳送已達 10 條，請明日再試',
      busy: '郵件傳送過於頻繁',
      failed: '郵件傳送失敗',
      success: '郵件已傳送，請注意查收'
    },
    registerUpdateApi: {
      emailCanNotEmpty: '郵箱不能為空',
      captchaCanNotEmpty: '驗證碼不能為空',
      passCanNotEmpty: '密碼不能為空',
      confirmPassCanNotEmpty: '二次重複密碼不能為空',
      passAndConfirmPassNotSame: '兩次輸入的密碼不一致',

      paramError: '引數異常',
      linkExpired: '連結超時，請重新獲取',
      unregistered: '您未註冊過賬號',
      captchaError: '驗證碼錯誤',
      captchaGetMailAndConfirmMailNotSame: '獲取驗證碼的郵箱與實際註冊郵箱不一致',
      mailExist: '郵箱已被佔用',
      exactlyTheSameAsBefore: '新郵箱與舊郵箱不能相同',
      passWeak: '密碼不符合規範',
      success: '修改成功',
      failed: '修改失敗'
    }
  },

  // views panel
  charts: {
    title: '已記錄的群組{title}發言量：{total}條',
    switch: '切換樣式',

    top10: {
      title: '群組發言 Top10',
      docPath: '/docs/#/?id=_6-查詢發言-top10',
      placeholder1: '查詢總髮言 Top10',
      placeholder2: '查詢當日發言 Top10'
    },
    daily: {
      title: '查詢指定日期各時段發言量',
      docPath: '/docs/#/?id=_7-查詢全天個時段發言量',
      placeholder1: '請選擇日期'
    },

    top10TotalApi: {
      title: '總'
    },
    top10DayApi: {
      title: '當日'
    },

    pieOption: {
      series: {
        name: '使用者暱稱'
      }
    },

    histOption: {
      yAxis: {
        name: '發言數量',
        axisLabel: {
          formatter: '條'
        }
      },
      top10: {
        xName: '使用者暱稱'
      },
      daily: {
        xName: '時間'
      }
    }
  },
  config: {
    on: '開',
    off: '關',
    title: '群組管理',
    docTitle: '檢視文件',
    docPath: '/docs/#/?id=_8-群組管理設定',
    overExceed: '字元長度超出限制範圍',
    underAttack: {
      title: '群組正遭受到攻擊',
      addBlacklist: '加入黑名單'
    },
    language: {
      title: '語言選擇'
    },
    record: {
      title: '發言統計',
      tips: '群組成員詳細的發言數量統計，精確到每小時'
    },
    serviceMessage: {
      title: 'Service Message',
      tips: '刪除成員加群、退群的 Service Message'
    },
    challenge: {
      title: '新人答題驗證',
      customizedIpt: {
        question: '請輸入自定義問題',
        answer: '請輸入自定義問題的答案',
        options: '請輸入自定義問題的選項'
      },
      customized: '自定義問答',
      random: '隨機加減法（100 以內）',
      captcha: '圖片驗證碼',
      failedBlock: '驗證失敗封禁使用者',
      timeoutBlock: '驗證超時封禁使用者',
      transfer: {
        tips: '群組成員認證流程不再展示在群組中，認證失敗後群組不會接受到任何訊息，歡迎訊息直接由 bot 傳送給成員',
        text: '認證過程轉移至機器人',
        guideText: '引導文案\n\n例如：歡迎新成員加入，請點選下方按獲取驗證資訊',
        guideBtn: '跳轉按鈕文案\n\n例如：點選驗證'
      },
      times: '允許驗證 {times} 次',
      timeout: '{timeout} 分鐘內未成功驗證踢除使用者',
      cooling: '被踢後再次進群間隔時間 {cooling} 分鐘',
      noCooling: '被踢後可立即重新加群'
    },
    authority: {
      title: '新人入群許可權',
      checkbox: {
        text: '允許傳送文字訊息',
        media: '允許傳送媒體訊息',
        polls: '允許發起投票',
        other: '允許傳送其他訊息',
        wpp: '允許傳送網頁預覽訊息'
      },
      limitLift: '{hour} 小時後解除禁言',
      alwaysLimit: '永不解除禁言'
    },
    autoKick: {
      title: '自動踢人選項（黑名單）',
      checkbox: {
        link: '進群直接傳送帶連結的',
        mention: '進群直接傳送帶 *(Mention) 的',
        markdown: '進群直接傳送帶 Markdown 的',
        media: '進群直接發媒體訊息的',
        forward: '進群直接轉發別人訊息的',
        simplify: '簡化訊息通知',
        nicknameSilence: '暱稱匹配 - 靜默踢人',
        timeoutSilence: '驗證超時 - 靜默踢人',
        messageSilence: '訊息匹配 - 靜默踢人',
        alwaysMatchNickname: '一直開啟暱稱校驗',
        useSameReg: '暱稱正則使用訊息正則',
        defaultReg: '使用系統預設正則'
      },
      customizedReg: {
        nicknamePlaceholder: '暱稱自定義正則，例如：微信*菠菜',
        messagePlaceholder: '訊息自定義正則，例如：微信*菠菜',
        notVerify: '新人發言 {count} 條後不再稽核',
        alwaysVerify: '一直開啟稽核'
      }
    },
    dailyFunc: {
      title: '日常功能',
      thead: {
        delete: '刪除',
        kick: '踢人',
        warn: '警告 ({ count }次)',
        ignore: '忽略',
        details: '說明'
      },
      tbody: {
        ignorePlaceholder: '例外 Inline Bot\n請使用 username',
        details: 'Inline Bot 相關訊息'
      }
    },
    autoReply: {
      title: '自動回覆',
      placeholder: '關鍵字 ~ 自動回覆內容\n按鈕名 - URL地址 ** 按鈕名 - URL地址\n\n關鍵字 ~ 自動回覆內容\n按鈕名  URL地址 ** 按鈕名 - URL地址\n\n特別注意：\n每條中間空一行，按鈕可以不設定\n各個分隔符號兩邊都要有空格\n回覆的內容不要另起一行，一條寫完\n\n* 分隔符號在英文輸入法下輸入\n* 分隔符號在英文輸入法下輸入\n* 分隔符號在英文輸入法下輸入'
    },
    welcome: {
      title: '新成員歡迎訊息設定',
      messagePlaceholder: '新成員歡迎語',
      buttonPlaceholder: '按鈕名 - URL地址 ** 按鈕名 - URL地址\n\n各個分隔符號兩邊都要有空格',
      autoDelete: '{minute} 分鐘後刪除歡迎訊息',
      neverDelete: '永不刪除歡迎訊息'
    },
    creator: {
      user: '修改使用者： {user}',
      time: '修改時間： {time}'
    },
    submit: '提 交',

    setGroupConfigApi: {
      success: '設定成功',
      failed: '設定失敗',
      customized: '自定义问答必须设置【问题、答案、选项】',
      guide: '转移验证流程到 Bot 必须设置【引导文案、跳转按钮文案】',
      paramError: '参数异常'
    }
  },
  member: {
    totalMessage: '發言總量: {total}',
    joinTime: '加入時間: {time}',
    latestMsgTime: '最後發言時間: {time}',

    head: {
      title: '成員發言詳細統計',
      placeholder: '請選擇月份'
    },
    weeks: {
      mon: '一',
      tues: '二',
      wed: '三',
      thur: '四',
      fri: '五',
      sat: '六',
      sun: '日'
    },

    changeMonth: {
      error: '日期不能為空'
    },
    memberDetailsApi: {
      hour: {
        title: '當月（時）總髮言量',
        xName: '時'
      },
      day: {
        title: '當月（天）總髮言量',
        xName: '日'
      },
      week: {
        title: '當月（星期）總髮言量',
        xName: '周'
      }
    },
    histOption: {
      yAxis: {
        name: '發言量',
        axisLabel: {
          formatter: '條'
        }
      }
    }
  },
  membersByAll: {
    head: {
      title: '全部記錄到的群組成員',
      docPath: '/docs/#/?id=_2-查詢成員（全部記錄到的）'
    }
  },
  membersByCount: {
    head: {
      title: '根據發言數量查詢成員',
      docPath: '/docs/#/?id=_3-查詢成員（發言數量）',
      placeholder1: '起始發言量',
      placeholder2: '終止發言量'
    },

    setDate: {
      error: '起始數量和終止數量均不能為空'
    }
  },
  membersByQuit: {
    head: {
      title: '一個月內離開群組的使用者',
      docPath: '/docs/#/?id=_5-查詢成員（最近一月離開）'
    }
  },
  membersByTime: {
    head: {
      title: '根據發言時間查詢成員',
      docPath: '/docs/#/?id=_4-查詢成員（最後發言時間）',
      placeholder1: '起始時間',
      placeholder2: '終止時間'
    },

    setDate: {
      error: '起始數量和終止數量均不能為空'
    }
  },
  panel: {
    title: '您管理中的群組'
  },

  // 组件
  components: {
    noData: '未查詢到資料',
    groupsList: {
      classTitle: '群地位',
      creator: '建立者',
      admin: '管理員',
      abnormal: '異常',
      totalMember: '群組成員總數',
      recordTotal: '記錄到的發言人數',
      joinTime: 'Bot 加入群組的時間'
    },
    homeNav: {
      homepage: '主 頁',
      flowChart: '流程圖',
      docs: '說明文件',
      donate: '捐贈',
      login: '登 陸'
    },
    membersList: {
      username: '使用者名稱',
      nickname: '暱稱',
      totalMessage: '發言總',
      joinTime: '入群時間',
      latestMsgTime: '最後發言',

      selectAll: '全選',
      totalLine: '共 {total} 條',
      option: {
        ten: '10 條/頁',
        twenty: '20 條/頁',
        thirty: '30 條/頁',
        fifty: '50 條/頁',
        seventy: '70 條/頁',
        hundred: '100 條/頁'
      },
      addBlockList: '加入黑名單',
      notAddBlockList: '不加入黑名單',
      kickSelectedMember: '踢除選中使用者',

      kickMembers: {
        error: '請先選擇要踢掉的群組成員',
        ask: '確定踢掉這 {count} 個群組成員麼'
      },
      kickMembersApi: {
        success: '執行成功，共計 {total} 條，<br><br>{success} 條執行成功，<br>{failed} 條執行失敗',
        paramError: '引數異常'
      }
    },
    panelHead: {
      hoverTitle: '檢視文件',
      search: '查詢'
    },
    panelNav: {
      changeLanguage: '更改語言',
      logout: '登 出',

      changeLanguageWin: {
        title: '更改語言（個人使用者）'
      },
      updateLanguageApi: {
        success: '更新成功',
        failed: '更新失敗'
      }
    },
    panelSidebar: {
      homepage: '首頁',
      config: '群組設定',
      membersByAll: '查詢成員（全部記錄到的）',
      membersByCount: '查詢成員（發言數量）',
      membersByTime: '查詢成員（最後發言時間）',
      membersByQuit: '查詢成員（最近一月離開）',
      top10: '查詢發言 Top10',
      daily: '查詢全天各時段發言量'
    },
    quitList: {
      username: '使用者名稱',
      nickname: '暱稱',
      totalMessage: '發言總',
      joinTime: '入群時間',
      latestMsgTime: '最後發言',
      quitTime: '退出時間',
      operator: '執行人',
      reason: '緣由',
      blacklist: '黑名單',

      reasonItem: {
        reg: '正則',
        url: '連結',
        self: '自己',
        mention: '提及',
        markdown: 'MD',
        verify: '驗證',
        timeout: '超時',
        nickname: '暱稱',
        via: '經由 Bot',
        warnVia: '經由 Warn',
        unknown: '未知'
      },
      blocked: {
        yes: '是',
        no: '否'
      },

      totalLine: '共 {total} 條',
      option: {
        ten: '10 條/頁',
        twenty: '20 條/頁',
        thirty: '30 條/頁',
        fifty: '50 條/頁',
        seventy: '70 條/頁',
        hundred: '100 條/頁'
      },

      reasonFilter: '緣由篩選',
      filterWarning: '請至少選擇一個選項'
    }
  }
}
