export function toast(msg) {
  if (document.getElementsByClassName('simple-toast').length !== 0) return

  const el = document.createElement('div')
  el.classList.add('simple-toast')
  el.style.width = '100%'
  el.style.lineHeight = '30px'
  el.style.position = 'absolute'
  el.style.top = '50%'
  el.style.left = '0'
  el.style.zIndex = '999'
  el.style.marginTop = '-15px'
  el.style.textAlign = 'center'

  const text = document.createElement('span')
  text.style.color = '#fff'
  text.style.display = 'inline-block'
  text.style.padding = '20px 15px'
  text.style.borderRadius = '8px'
  text.style.background = 'rgba(0, 0, 0, 0.6)'
  text.innerText = msg
  el.append(text)
  document.body.append(el)
  setTimeout(() => {
    document.body.removeChild(el)
  }, 3000)
}

export function loading(show) {
  const target = document.getElementsByClassName('simple-loading')

  if (!show) {
    if (target.length !== 0) document.body.removeChild(target[0])
    return
  }

  if (target.length !== 0) return

  const div = document.createElement('div')
  div.classList.add('simple-loading')
  div.style.display = 'table'
  div.style.width = '100%'
  div.style.height = '100%'
  div.style.background = 'rgba(255, 255, 255, 0.3)'
  div.style.position = 'absolute'
  div.style.top = '0'
  div.style.left = '0'
  div.style.zIndex = '999'

  const em = document.createElement('em')
  em.style.display = 'table-cell'
  em.style.verticalAlign = 'middle'

  const img = document.createElement('img')
  img.src = '/img/loading-captcha.svg'
  img.style.display = 'block'
  img.style.width = '100px'
  img.style.margin = '0 auto'

  em.append(img)
  div.append(em)
  document.body.append(div)
}
