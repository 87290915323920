const state = {
  gid: 0, // 只应用到从 panel 选择的群组时内部隐形参数供给 config 拿到 gid
  record: false,
  username: '',
  language: [],
  userLanguage: '',
  member: {
    uid: 0
  },
  panelNav: {
    groupName: '',
    groupAvatar: '',
    nickname: '',
    userAvatar: ''
  }
}

export default state
