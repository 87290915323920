import i18n from '../i18n/index.js'
import Store from '../store/index.js'
import { loggedApi } from '../api/index.js'
import { toast, loading } from '../utils/alert.js'
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  // 错误页
  {
    path: '/404',
    name: '404',
    meta: {
      auth: false
    },
    component: () => import('../views/404.vue')
  },

  // 主页
  {
    path: '/',
    name: 'Home',
    meta: {
      auth: false
    },
    component: () => import('../views/home/Home.vue')
  },
  {
    path: '/flow_chart',
    name: 'FlowChart',
    meta: {
      auth: false
    },
    component: () => import('../views/home/FlowChart.vue')
  },
  {
    path: '/docs',
    name: 'Docs',
    meta: {
      auth: false
    },
    component: () => import('../views/home/Docs.vue')
  },
  {
    path: '/donate',
    name: 'Donate',
    meta: {
      auth: false
    },
    component: () => import('../views/home/Donate.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      auth: true
    },
    component: () => import('../views/Login.vue')
  },
  {
    path: '/auto_login',
    name: 'AutoLogin',
    meta: {
      auth: false
    },
    component: () => import('../views/AutoLogin.vue')
  },
  {
    path: '/register',
    name: 'Register',
    meta: {
      auth: false
    },
    component: () => import('../views/Register.vue')
  },
  {
    path: '/update_pass',
    name: 'UpdatePass',
    meta: {
      auth: false
    },
    component: () => import('../views/RegisterUpdate.vue')
  },
  {
    path: '/update_mail',
    name: 'UpdateMail',
    meta: {
      auth: false
    },
    component: () => import('../views/RegisterUpdate.vue')
  },

  // 管理页面
  {
    path: '/panel',
    name: 'Panel',
    meta: {
      auth: true
    },
    component: () => import('../views/panel/Panel.vue')
  },
  {
    path: '/panel/config',
    name: 'Config',
    meta: {
      auth: true
    },
    component: () => import('../views/panel/Config.vue')
  },
  {
    path: '/panel/members_by_all',
    name: 'MembersByAll',
    meta: {
      auth: true
    },
    component: () => import('../views/panel/MembersByAll.vue')
  },
  {
    path: '/panel/members_by_count',
    name: 'MembersByCount',
    meta: {
      auth: true
    },
    component: () => import('../views/panel/MembersByCount.vue')
  },
  {
    path: '/panel/members_by_time',
    name: 'MembersByTime',
    meta: {
      auth: true
    },
    component: () => import('../views/panel/MembersByTime.vue')
  },
  {
    path: '/panel/members_by_quit',
    name: 'MembersByQuit',
    meta: {
      auth: true
    },
    component: () => import('../views/panel/MembersByQuit.vue')
  },
  {
    path: '/panel/top10',
    name: 'Top10',
    meta: {
      auth: true
    },
    component: () => import('../views/panel/Charts.vue')
  },
  {
    path: '/panel/daily',
    name: 'Daily',
    meta: {
      auth: true
    },
    component: () => import('../views/panel/Charts.vue')
  },
  {
    path: '/panel/member',
    name: 'Member',
    meta: {
      auth: true
    },
    component: () => import('../views/panel/Member.vue')
  },

  // 测试
  {
    path: '/test',
    name: 'Test',
    meta: {
      auth: false
    },
    component: () => import('../views/Test.vue')
  },

  // 全局 404
  // 此处需特别注意至于最底部
  {
    path: '/:catchAll(.*)',
    redirect: '/404'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// 路由拦截（不可以加 async 异步请求
router.beforeEach((to, from, next) => {
  if (to.meta.auth) {
    loading(true)
    loggedApi().then(succ => {
      if (succ.code === 10000) {
        if (succ.data.group) { // 避免始终以第一次点击的群组id请求
          if (from.path === '/' && to.path === '/panel/config') {
            Store.commit('set', { gid: succ.data.group.gid })
          }
          Store.commit('setPanelNav', {
            groupName: succ.data.group.title,
            groupAvatar: succ.data.group.pic
          })
        }
        Store.commit('set', {
          record: succ.data.record,
          userLanguage: succ.data.user.language
        })
        Store.commit('setPanelNav', {
          nickname: succ.data.user.nickname,
          userAvatar: succ.data.user.pic
        })
        i18n.global.locale = succ.data.user.language.replace('-', '_')
        localStorage.setItem('lang', succ.data.user.language)

        if (to.name === 'Login') next({ name: 'Panel' })
      } else {
        if (to.meta.auth && to.name !== 'Login') {
          toast(i18n.global.t('router.timeout'))
          next({ name: 'Login' })
        }
      }
      next()
    }).finally(() => {
      loading(false)
    })
  } else {
    next()
  }
})

export default router
