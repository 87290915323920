const getters = {
  get: state => target => {
    return state[target]
  },
  getMember: state => target => {
    return state.member[target]
  },
  getPanelNav: state => target => {
    return state.panelNav[target]
  }
}

export default getters
