module.exports = {
  // common
  ok: '确定',
  cancel: '取消',
  language: {
    en_US: 'English',
    ja_JP: '日本語',
    ru_RU: 'Pусский',
    zh_CN: '简体中文',
    zh_HK: '繁體中文（香港）',
    zh_TW: '繁體中文（台灣）'
  },

  // router
  router: {
    timeout: '登陆超时'
  },

  // request
  request: {
    expired: '登陆过期',
    quit: 'Bot 已不在群组中',
    notAdmin: '非法登陆，您不是群组管理员',
    maintain: '系统维护中',
    requestFailed: '服务器请求失败，请稍后再试'
  },

  // views
  autoLogin: {
    tips: '认证中{dot}',

    autoLoginApi: {
      paramError: '参数异常',
      linkExpired: '认证链接已过期'
    }
  },
  login: {
    inputEmail: '请输入邮箱',
    inputPass: '请输入密码',
    inputCaptcha: '请输入验证码',
    captchaFailed: '获取失败',
    register: '注册账号',
    update: '修改信息',
    login: '登 陆',
    logging: '登陆中...',

    captchaApi: {
      captchaOverTimes: '今日验证码获取次数已达上限，请明日再试'
    },
    loginApi: {
      emailCanNotEmpty: '邮箱不能为空',
      passCanNotEmpty: '密码不能为空',
      captchaCanNotEmpty: '验证码不能为空',

      paramError: '参数异常',
      mailError: '邮箱格式错误',
      captchaError: '验证码错误',
      loginFailedOverTimes: '账号或密码错误次数过多，请明日再试',
      mailOrPassError: '邮箱或密码错误',
      busy: '服务器繁忙，请稍后再试'
    },

    win: {
      register: '<p>请私聊 Bot 发送</p><strong style="color: #ff3333;">!register</strong> 获取注册链接',
      update: '<p>请私聊 Bot 发送</p><strong style="color: #ff3333;">!update-pass</strong> 获取重置密码的链接<br><strong style="color: #ff3333;">!update-mail</strong> 获取更换邮箱的链接'
    }
  },
  register: {
    title: '账号注册',
    inputEmail: '请输入邮箱',
    inputCaptcha: '请输入验证码',
    inputPass: '请输入密码',
    inputConfirmPass: '请再次输入密码',
    register: '注 册',
    registering: '注册中...',

    captchaText: '获取验证码',

    mailApi: {
      mailLimit: '邮箱地址错误，仅支持 qq、163、126、yeah、sina、gmail、icloud、foxmail、outlook',

      paramError: '参数异常',
      linkExpired: '链接超时，请重新获取',
      mailExist: '邮箱已被占用',
      overTimes: '当日发送已达 10 条，请明日再试',
      busy: '邮件发送过于频繁',
      failed: '邮件发送失败',
      success: '邮件已发送，请注意查收'
    },
    registerApi: {
      emailCanNotEmpty: '邮箱不能为空',
      captchaCanNotEmpty: '验证码不能为空',
      passCanNotEmpty: '密码不能为空',
      confirmPassCanNotEmpty: '二次重复密码不能为空',
      passAndConfirmPassNotSame: '两次输入的密码不一致',

      paramError: '参数异常',
      linkExpired: '链接超时，请重新获取',
      registered: '您已注册过账号',
      captchaError: '验证码错误',
      captchaGetMailAndConfirmMailNotSame: '获取验证码的邮箱与实际注册邮箱不一致',
      mailExist: '邮箱已被占用',
      passWeak: '密码不符合规范',
      success: '注册成功',
      failed: '注册失败'
    }
  },
  registerUpdate: {
    titlePass: '重置密码',
    titleMail: '更换邮箱',
    inputEmail: '请输入新邮箱',
    inputCaptcha: '请输入验证码',
    inputPass: '请输入密码',
    inputConfirmPass: '请再次输入密码',
    update: '提 交',
    updating: '提交中...',

    captchaText: '获取验证码',

    mailApi: {
      mailLimit: '邮箱地址错误，仅支持 qq、163、126、yeah、sina、gmail、icloud、foxmail、outlook',

      paramError: '参数异常',
      linkExpired: '链接超时，请重新获取',
      mailExist: '邮箱已被占用',
      overTimes: '当日发送已达 10 条，请明日再试',
      busy: '邮件发送过于频繁',
      failed: '邮件发送失败',
      success: '邮件已发送，请注意查收'
    },
    registerUpdateApi: {
      emailCanNotEmpty: '邮箱不能为空',
      captchaCanNotEmpty: '验证码不能为空',
      passCanNotEmpty: '密码不能为空',
      confirmPassCanNotEmpty: '二次重复密码不能为空',
      passAndConfirmPassNotSame: '两次输入的密码不一致',

      paramError: '参数异常',
      linkExpired: '链接超时，请重新获取',
      unregistered: '您未注册过账号',
      captchaError: '验证码错误',
      captchaGetMailAndConfirmMailNotSame: '获取验证码的邮箱与实际注册邮箱不一致',
      mailExist: '邮箱已被占用',
      exactlyTheSameAsBefore: '新邮箱与旧邮箱不能相同',
      passWeak: '密码不符合规范',
      success: '修改成功',
      failed: '修改失败'
    }
  },

  // views panel
  charts: {
    title: '已记录的群组{title}发言量：{total}条',
    switch: '切换样式',

    top10: {
      title: '群组发言 Top10',
      docPath: '/docs/#/?id=_6-查询发言-top10',
      placeholder1: '查询总发言 Top10',
      placeholder2: '查询当日发言 Top10'
    },
    daily: {
      title: '查询指定日期各时段发言量',
      docPath: '/docs/#/?id=_7-查询全天个时段发言量',
      placeholder1: '请选择日期'
    },

    top10TotalApi: {
      title: '总'
    },
    top10DayApi: {
      title: '当日'
    },

    pieOption: {
      series: {
        name: '用户昵称'
      }
    },

    histOption: {
      yAxis: {
        name: '发言数量',
        axisLabel: {
          formatter: '条'
        }
      },
      top10: {
        xName: '用户昵称'
      },
      daily: {
        xName: '时间'
      }
    }
  },
  config: {
    on: '开',
    off: '关',
    title: '群组管理',
    docTitle: '查看文档',
    docPath: '/docs/#/?id=_8-群组管理设置',
    overExceed: '字符长度超出限制范围',
    underAttack: {
      title: '群组正遭受到攻击',
      addBlacklist: '加入黑名单'
    },
    language: {
      title: '语言选择'
    },
    record: {
      title: '发言统计',
      tips: '群组成员详细的发言数量统计，精确到每小时'
    },
    serviceMessage: {
      title: 'Service Message',
      tips: '删除成员加群、退群的 Service Message'
    },
    challenge: {
      title: '新人答题验证',
      customizedIpt: {
        question: '请输入自定义问题',
        answer: '请输入自定义问题的答案',
        options: '请输入自定义问题的选项'
      },
      customized: '自定义问答',
      random: '随机加减法（100 以内）',
      captcha: '图片验证码',
      failedBlock: '验证失败封禁用户',
      timeoutBlock: '验证超时封禁用户',
      transfer: {
        tips: '群组成员认证流程不再展示在群组中，认证失败后群组不会接受到任何消息，欢迎消息直接由 bot 发送给成员',
        text: '认证过程转移至机器人',
        guideText: '引导文案\n\n例如：欢迎新成员加入，请点击下方按获取验证信息',
        guideBtn: '跳转按钮文案\n\n例如：点击验证'
      },
      times: '允许验证 {times} 次',
      timeout: '{timeout} 分钟内未成功验证踢除用户',
      cooling: '被踢后再次进群间隔时间 {cooling} 分钟',
      noCooling: '被踢后可立即重新加群'
    },
    authority: {
      title: '新人入群权限',
      checkbox: {
        text: '允许发送文字消息',
        media: '允许发送媒体消息',
        polls: '允许发起投票',
        other: '允许发送其他消息',
        wpp: '允许发送网页预览消息'
      },
      limitLift: '{hour} 小时后解除禁言',
      alwaysLimit: '永不解除禁言'
    },
    autoKick: {
      title: '自动踢人选项（黑名单）',
      checkbox: {
        link: '进群直接发送带链接的',
        mention: '进群直接发送带 *(Mention) 的',
        markdown: '进群直接发送带 Markdown 的',
        media: '进群直接发媒体消息的',
        forward: '进群直接转发别人消息的',
        simplify: '简化消息通知',
        nicknameSilence: '昵称匹配 - 静默踢人',
        timeoutSilence: '验证超时 - 静默踢人',
        messageSilence: '消息匹配 - 静默踢人',
        alwaysMatchNickname: '一直开启昵称校验',
        useSameReg: '昵称正则使用消息正则',
        defaultReg: '使用系统预设正则'
      },
      customizedReg: {
        nicknamePlaceholder: '昵称自定义正则，例如：微信*菠菜',
        messagePlaceholder: '消息自定义正则，例如：微信*菠菜',
        notVerify: '新人发言 {count} 条后不再审核',
        alwaysVerify: '一直开启审核'
      }
    },
    dailyFunc: {
      title: '日常功能',
      thead: {
        delete: '删除',
        kick: '踢人',
        warn: '警告 ({ count }次)',
        ignore: '忽略',
        details: '说明'
      },
      tbody: {
        ignorePlaceholder: '例外 Inline Bot\n请使用 username',
        details: 'Inline Bot 相关消息'
      }
    },
    autoReply: {
      title: '自动回复',
      placeholder: '关键字 ~ 自动回复内容\n按钮名 - URL地址 ** 按钮名 - URL地址\n\n关键字 ~ 自动回复内容\n按钮名  URL地址 ** 按钮名 - URL地址\n\n特别注意：\n每条中间空一行，按钮可以不设置\n各个分隔符号两边都要有空格\n回复的内容不要另起一行，一条写完\n\n* 分隔符号在英文输入法下输入\n* 分隔符号在英文输入法下输入\n* 分隔符号在英文输入法下输入'
    },
    welcome: {
      title: '新成员欢迎消息设置',
      messagePlaceholder: '新成员欢迎语',
      buttonPlaceholder: '按钮名 - URL地址 ** 按钮名 - URL地址\n\n各个分隔符号两边都要有空格',
      autoDelete: '{minute} 分钟后删除欢迎消息',
      neverDelete: '永不删除欢迎消息'
    },
    creator: {
      user: '修改用户： {user}',
      time: '修改时间： {time}'
    },
    submit: '提 交',

    setGroupConfigApi: {
      success: '设置成功',
      failed: '设置失败',
      customized: '自定义问答必须设置【问题、答案、选项】',
      guide: '转移验证流程到 Bot 必须设置【引导文案、跳转按钮文案】',
      paramError: '参数异常'
    }
  },
  member: {
    totalMessage: '发言总量: {total}',
    joinTime: '加入时间: {time}',
    latestMsgTime: '最后发言时间: {time}',

    head: {
      title: '成员发言详细统计',
      placeholder: '请选择月份'
    },
    weeks: {
      mon: '一',
      tues: '二',
      wed: '三',
      thur: '四',
      fri: '五',
      sat: '六',
      sun: '日'
    },

    changeMonth: {
      error: '日期不能为空'
    },
    memberDetailsApi: {
      hour: {
        title: '当月（时）总发言量',
        xName: '时'
      },
      day: {
        title: '当月（天）总发言量',
        xName: '日'
      },
      week: {
        title: '当月（星期）总发言量',
        xName: '周'
      }
    },
    histOption: {
      yAxis: {
        name: '发言量',
        axisLabel: {
          formatter: '条'
        }
      }
    }
  },
  membersByAll: {
    head: {
      title: '全部记录到的群组成员',
      docPath: '/docs/#/?id=_2-查询成员（全部记录到的）'
    }
  },
  membersByCount: {
    head: {
      title: '根据发言数量查询成员',
      docPath: '/docs/#/?id=_3-查询成员（发言数量）',
      placeholder1: '起始发言量',
      placeholder2: '终止发言量'
    },

    setDate: {
      error: '起始数量和终止数量均不能为空'
    }
  },
  membersByQuit: {
    head: {
      title: '一个月内离开群组的用户',
      docPath: '/docs/#/?id=_5-查询成员（最近一月离开）'
    }
  },
  membersByTime: {
    head: {
      title: '根据发言时间查询成员',
      docPath: '/docs/#/?id=_4-查询成员（最后发言时间）',
      placeholder1: '起始时间',
      placeholder2: '终止时间'
    },

    setDate: {
      error: '起始数量和终止数量均不能为空'
    }
  },
  panel: {
    title: '您管理中的群组'
  },

  // 组件
  components: {
    noData: '未查询到数据',
    groupsList: {
      classTitle: '群地位',
      creator: '创建者',
      admin: '管理员',
      abnormal: '异常',
      totalMember: '群组成员总数',
      recordTotal: '记录到的发言人数',
      joinTime: 'Bot 加入群组的时间'
    },
    homeNav: {
      homepage: '主 页',
      flowChart: '流程图',
      docs: '说明文档',
      donate: '捐赠',
      login: '登 陆'
    },
    membersList: {
      username: '用户名',
      nickname: '昵称',
      totalMessage: '发言总',
      joinTime: '入群时间',
      latestMsgTime: '最后发言',

      selectAll: '全选',
      totalLine: '共 {total} 条',
      option: {
        ten: '10 条/页',
        twenty: '20 条/页',
        thirty: '30 条/页',
        fifty: '50 条/页',
        seventy: '70 条/页',
        hundred: '100 条/页'
      },
      addBlockList: '加入黑名单',
      notAddBlockList: '不加入黑名单',
      kickSelectedMember: '踢除选中用户',

      kickMembers: {
        error: '请先选择要踢掉的群组成员',
        ask: '确定踢掉这 {count} 个群组成员么'
      },
      kickMembersApi: {
        success: '执行成功，共计 {total} 条，<br><br>{success} 条执行成功，<br>{failed} 条执行失败',
        paramError: '参数异常'
      }
    },
    panelHead: {
      hoverTitle: '查看文档',
      search: '查询'
    },
    panelNav: {
      changeLanguage: '更改语言',
      logout: '登 出',

      changeLanguageWin: {
        title: '更改语言（个人用户）'
      },
      updateLanguageApi: {
        success: '更新成功',
        failed: '更新失败'
      }
    },
    panelSidebar: {
      homepage: '首页',
      config: '群组设置',
      membersByAll: '查询成员（全部记录到的）',
      membersByCount: '查询成员（发言数量）',
      membersByTime: '查询成员（最后发言时间）',
      membersByQuit: '查询成员（最近一月离开）',
      top10: '查询发言 Top10',
      daily: '查询全天各时段发言量'
    },
    quitList: {
      username: '用户名',
      nickname: '昵称',
      totalMessage: '发言总',
      joinTime: '入群时间',
      latestMsgTime: '最后发言',
      quitTime: '退出时间',
      operator: '执行人',
      reason: '缘由',
      blacklist: '黑名单',

      reasonItem: {
        reg: '正则',
        url: '链接',
        self: '自己',
        mention: '提及',
        markdown: 'MD',
        verify: '验证',
        timeout: '超时',
        nickname: '昵称',
        via: '经由 Bot',
        warnVia: '经由 Warn',
        unknown: '未知'
      },
      blocked: {
        yes: '是',
        no: '否'
      },

      totalLine: '共 {total} 条',
      option: {
        ten: '10 条/页',
        twenty: '20 条/页',
        thirty: '30 条/页',
        fifty: '50 条/页',
        seventy: '70 条/页',
        hundred: '100 条/页'
      },

      reasonFilter: '缘由筛选',
      filterWarning: '请至少选择一个选项'
    }
  }
}
