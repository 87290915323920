module.exports = {
  // common
  ok: 'OK',
  cancel: 'Cancel',
  language: {
    en_US: 'English',
    ja_JP: '日本語',
    ru_RU: 'Pусский',
    zh_CN: '简体中文',
    zh_HK: '繁體中文（香港）',
    zh_TW: '繁體中文（台灣）'
  },

  // router
  router: {
    timeout: 'Login Timeout'
  },

  // request
  request: {
    expired: 'Login Expired',
    quit: 'Bot is no longer in the group',
    notAdmin: 'Illegal login, you are not a group administrator',
    maintain: 'System under maintenance',
    requestFailed: 'Server request failed, please try again later'
  },

  // views
  autoLogin: {
    tips: 'Authenticating{dot}',

    autoLoginApi: {
      paramError: 'Parameter abnormal',
      linkExpired: 'The authentication link has expired'
    }
  },
  login: {
    inputEmail: 'Please input email',
    inputPass: 'Please input password',
    inputCaptcha: 'Please input captcha code',
    captchaFailed: 'Require failed',
    register: 'Register an account',
    update: 'Modify information',
    login: 'Sign in',
    logging: 'Logging in...',

    captchaApi: {
      captchaOverTimes: 'The number of verification codes obtained today has reached the upper limit, please try again tomorrow'
    },
    loginApi: {
      emailCanNotEmpty: 'Email can not be empty',
      passCanNotEmpty: 'Password can not be empty',
      captchaCanNotEmpty: 'Captcha can not be empty',

      paramError: 'Parameter abnormal',
      mailError: 'Email format error',
      captchaError: 'Captcha code error',
      loginFailedOverTimes: 'The account or password is wrong too many times, please try again tomorrow',
      mailOrPassError: 'Mail or password is incorrect',
      busy: 'Server is busy, please try again later'
    },

    win: {
      register: '<p>Please private chat Bot send</p><strong style="color: #ff3333;">!register</strong> Get registration link',
      update: '<p>Please private chat Bot send</p><strong style="color: #ff3333;">!update-pass</strong> Get the link to reset your password<br><strong style="color: #ff3333;">!update-mail</strong> Get the link to replace the email'
    }
  },
  register: {
    title: 'Register an account',
    inputEmail: 'Please input email',
    inputCaptcha: 'Please input captcha code',
    inputPass: 'Please input password',
    inputConfirmPass: 'Please enter the password again',
    register: 'Register',
    registering: 'Registering...',

    captchaText: 'Get captcha code',

    mailApi: {
      mailLimit: 'Incorrect email address, only supports qq、163、126、yeah、sina、gmail、icloud、foxmail、outlook',

      paramError: 'Parameter abnormal',
      linkExpired: 'The authentication link has expired',
      mailExist: 'Email is already occupied',
      overTimes: '10 items have been sent that day, please try again tomorrow',
      busy: 'Email is sent too frequently',
      failed: 'Failed to send mail',
      success: 'The email has been sent, please check it'
    },
    registerApi: {
      emailCanNotEmpty: 'Email can not be empty',
      captchaCanNotEmpty: 'Captcha can not be empty',
      passCanNotEmpty: 'Password can not be empty',
      confirmPassCanNotEmpty: 'The repetitive password cannot be empty',
      passAndConfirmPassNotSame: 'The two passwords entered are inconsistent',

      paramError: 'Parameter abnormal',
      linkExpired: 'The authentication link has expired',
      registered: 'You have already registered an account',
      captchaError: 'Captcha code error',
      captchaGetMailAndConfirmMailNotSame: 'The email address for obtaining the verification code is inconsistent with the actual registered email address',
      mailExist: 'Email is already occupied',
      passWeak: 'Password does not meet specifications',
      success: 'Registration success',
      failed: 'Registration failed'
    }
  },
  registerUpdate: {
    titlePass: 'Reset Password',
    titleMail: 'Change Email',
    inputEmail: 'Please enter a new email',
    inputCaptcha: 'Please input captcha code',
    inputPass: 'Please input password',
    inputConfirmPass: 'Please enter the password again',
    update: 'Submit',
    updating: 'Submitting...',

    captchaText: 'Get captcha code',

    mailApi: {
      mailLimit: 'Incorrect email address, only supports qq、163、126、yeah、sina、gmail、icloud、foxmail、outlook',

      paramError: 'Parameter abnormal',
      linkExpired: 'The authentication link has expired',
      mailExist: 'Email is already occupied',
      overTimes: '10 items have been sent that day, please try again tomorrow',
      busy: 'Email is sent too frequently',
      failed: 'Failed to send mail',
      success: 'The email has been sent, please check it'
    },
    registerUpdateApi: {
      emailCanNotEmpty: 'Email can not be empty',
      captchaCanNotEmpty: 'Captcha can not be empty',
      passCanNotEmpty: 'Password can not be empty',
      confirmPassCanNotEmpty: 'The repetitive password cannot be empty',
      passAndConfirmPassNotSame: 'The two passwords entered are inconsistent',

      paramError: 'Parameter abnormal',
      linkExpired: 'The authentication link has expired',
      unregistered: 'You have not registered an account',
      captchaError: 'Captcha code error',
      captchaGetMailAndConfirmMailNotSame: 'The email address for obtaining the verification code is inconsistent with the actual registered email address',
      mailExist: 'Email is already occupied',
      exactlyTheSameAsBefore: 'The new email cannot be the same as the old email',
      passWeak: 'Password does not meet specifications',
      success: 'Successfully modified',
      failed: 'Failed modified'
    }
  },

  // views panel
  charts: {
    title: 'Recorded group {title} speeches: {total}',
    switch: 'Switch Style',

    top10: {
      title: 'Group speech Top10',
      docPath: '/docs/#/?id=_6-查询发言-top10',
      placeholder1: 'Top10 speech on the total',
      placeholder2: 'Top10 speeches on the day of inquiry'
    },
    daily: {
      title: 'Query the volume of speeches in each period of the specified date',
      docPath: '/docs/#/?id=_7-查询全天个时段发言量',
      placeholder1: 'Please select date'
    },

    top10TotalApi: {
      title: 'all'
    },
    top10DayApi: {
      title: 'today'
    },

    pieOption: {
      series: {
        name: 'Nickname'
      }
    },

    histOption: {
      yAxis: {
        name: 'Number of speeches',
        axisLabel: {
          formatter: ''
        }
      },
      top10: {
        xName: 'Nickname'
      },
      daily: {
        xName: 'Time'
      }
    }
  },
  config: {
    on: 'ON',
    off: 'OFF',
    title: 'Group Management',
    docTitle: 'View Document',
    docPath: '/docs/#/?id=_8-群组管理设置',
    overExceed: 'The character length exceeds the limit',
    underAttack: {
      title: 'Group is under attack',
      addBlacklist: 'Add to blacklist'
    },
    language: {
      title: 'Language selection'
    },
    record: {
      title: 'Speaking statistics',
      tips: 'Group members detailed statistics on the number of speeches, accurate to the hour'
    },
    serviceMessage: {
      title: 'Service Message',
      tips: 'Delete the service message of members entering and leaving the group'
    },
    challenge: {
      title: 'New members answer verification',
      customizedIpt: {
        question: 'Please enter a custom question',
        answer: 'Please enter the answer to the custom question',
        options: 'Please enter options for custom question'
      },
      customized: 'Custom Q&A',
      random: 'Random addition and subtraction (within 100)',
      captcha: 'Image captcha code',
      failedBlock: 'Failed to verify user banned',
      timeoutBlock: 'timeout to verify user banned',
      transfer: {
        tips: 'The group member authentication process is no longer displayed in the group. After authentication fails, the group will not receive any messages. The welcome message is sent directly to the members by the bot',
        text: 'Transfer of the certification process to the robot',
        guideText: 'Guide copy\n\nFor example: welcome new members to join, please click below to get verification information',
        guideBtn: 'Jump button copy\n\nFor example: click to verify'
      },
      times: 'Allow {times} verifications',
      timeout: 'The user is not successfully verified and kicked out within {timeout} minutes',
      cooling: 'After being kicked, the interval between entering the group again is {cooling} minutes',
      noCooling: 'Can be rejoined immediately after being kicked'
    },
    authority: {
      title: 'New members permission to join the group',
      checkbox: {
        text: 'Allow sending text messages',
        media: 'Allow sending media messages',
        polls: 'Allow sending voting',
        other: 'Allow sending other messages',
        wpp: 'Allow sending web preview messages'
      },
      limitLift: 'Release after {hour} hours',
      alwaysLimit: 'Never release'
    },
    autoKick: {
      title: 'Automatic kicking option (blacklist)',
      checkbox: {
        link: 'Directly send with links into the group, when joined',
        mention: 'Directly send with *(Mention) into the group, when joined',
        markdown: 'Directly send with Markdown into the group, when joined',
        media: 'Directly send media into the group, when joined',
        forward: 'Directly forward other people’s messages into the group, when joined',
        simplify: 'Simplify message notification',
        nicknameSilence: 'Nickname matching - silent kick',
        timeoutSilence: 'Verification timed out - silent kick',
        messageSilence: 'Message matching - silent kick',
        alwaysMatchNickname: 'Always enable nickname verification',
        useSameReg: 'Nickname regular use message regular',
        defaultReg: 'Use system preset regular'
      },
      customizedReg: {
        nicknamePlaceholder: 'Nickname custom regular, for example: WeChat*WhatsApp',
        messagePlaceholder: 'Message custom regular, for example: WeChat*WhatsApp',
        notVerify: 'Newcomers will not review after {count} speeches',
        alwaysVerify: 'Always review'
      }
    },
    dailyFunc: {
      title: 'Daily Function',
      thead: {
        delete: 'Del',
        kick: 'Kick',
        warn: 'Warn ({ count }times)',
        ignore: 'Ignore',
        details: 'Details'
      },
      tbody: {
        ignorePlaceholder: 'Details Inline Bot\nPlease use username',
        details: 'Inline Bot Related Message'
      }
    },
    autoReply: {
      title: 'Automatic response',
      placeholder: 'Keywords ~ Auto reply content\nButton name - URL address ** Button name - URL address\n\nKeywords ~ Auto reply content\nButton name - URL address ** Button name - URL address\n\nSpecial attention:\nThere is a blank line in the middle of each line, and the button does not need to be set\nThere must be spaces on both sides of each separator\nDo not start a new line in the reply content, and write one line\n\n* The separator is entered in the English input method\n* The separator is entered in the English input method\n* The separator is entered in the English input method'
    },
    welcome: {
      title: 'New member welcome message settings',
      messagePlaceholder: 'Welcome message for new members',
      buttonPlaceholder: 'Button name - URL address ** Button name - URL address\n\nThere must be spaces on both sides of each separator',
      autoDelete: 'Delete the welcome message after {minute} minutes',
      neverDelete: 'Never delete welcome message'
    },
    creator: {
      user: 'Modify user: {user}',
      time: 'Change time: {time}'
    },
    submit: 'Submit',

    setGroupConfigApi: {
      success: 'Set successfully',
      failed: 'Setup failed',
      customized: 'Custom Q&A must be set [question, answer, option]',
      guide: 'To transfer the verification process to Bot, you must set [Guide Copy, Jump Button Copy]',
      paramError: 'Parameter abnormal'
    }
  },
  member: {
    totalMessage: 'Total speech: {total}',
    joinTime: 'Join time: {time}',
    latestMsgTime: 'Last message time: {time}',

    head: {
      title: 'Detailed statistics of members’ speeches',
      placeholder: 'Please select month'
    },
    weeks: {
      mon: 'Mon',
      tues: 'Tues',
      wed: 'Wed',
      thur: 'Thur',
      fri: 'Fri',
      sat: 'Sat',
      sun: 'Sun'
    },

    changeMonth: {
      error: 'Date cannot be empty'
    },
    memberDetailsApi: {
      hour: {
        title: 'Total speech volume of the month (hour)',
        xName: 'Hour'
      },
      day: {
        title: 'Total speech volume of the month (day)',
        xName: 'Day'
      },
      week: {
        title: 'Total speech volume of the month (week)',
        xName: 'Week'
      }
    },
    histOption: {
      yAxis: {
        name: 'Volume of speech',
        axisLabel: {
          formatter: ''
        }
      }
    }
  },
  membersByAll: {
    head: {
      title: 'All recorded group members',
      docPath: '/docs/#/?id=_2-查询成员（全部记录到的）'
    }
  },
  membersByCount: {
    head: {
      title: 'Query members based on the number of statements',
      docPath: '/docs/#/?id=_3-查询成员（发言数量）',
      placeholder1: 'Initial speech',
      placeholder2: 'Termination of speech'
    },

    setDate: {
      error: 'Neither the starting quantity nor the ending quantity can be empty'
    }
  },
  membersByQuit: {
    head: {
      title: 'Users who left the group within a month',
      docPath: '/docs/#/?id=_5-查询成员（最近一月离开）'
    }
  },
  membersByTime: {
    head: {
      title: 'Query members based on speaking time',
      docPath: '/docs/#/?id=_4-查询成员（最后发言时间）',
      placeholder1: 'Start time',
      placeholder2: 'Stop time'
    },

    setDate: {
      error: 'Neither the starting quantity nor the ending quantity can be empty'
    }
  },
  panel: {
    title: 'Groups you manage'
  },

  // 组件
  components: {
    noData: 'No data found',
    groupsList: {
      classTitle: 'Group status',
      creator: 'Creator',
      admin: 'Administrator',
      abnormal: 'Abnormal',
      totalMember: 'Total number of group members',
      recordTotal: 'Number of speakers recorded',
      joinTime: 'Time when bot joined the group'
    },
    homeNav: {
      homepage: 'Homepage',
      flowChart: 'Flow Chart',
      docs: 'Documentation',
      donate: 'Donate',
      login: 'Sign in'
    },
    membersList: {
      username: 'Username',
      nickname: 'Nickname',
      totalMessage: 'Total speech',
      joinTime: 'Join time',
      latestMsgTime: 'Final speech',

      selectAll: 'Select all',
      totalLine: '{total} total',
      option: {
        ten: '10 items/page',
        twenty: '20 items/page',
        thirty: '30 items/page',
        fifty: '50 items/page',
        seventy: '70 items/page',
        hundred: '100 items/page'
      },
      addBlockList: 'Add to blacklist',
      notAddBlockList: 'Do not add to blacklist',
      kickSelectedMember: 'Kick selected users',

      kickMembers: {
        error: 'Please select the group members to be kicked',
        ask: 'Are you sure to kick these {count} group members?'
      },
      kickMembersApi: {
        success: 'Successfully executed, {total} in total, <br><br>{success} successfully executed, and <br>{failed} failed',
        paramError: 'Parameter abnormal'
      }
    },
    panelHead: {
      hoverTitle: 'View document',
      search: 'Search'
    },
    panelNav: {
      changeLanguage: 'Change language',
      logout: 'Sign out',

      changeLanguageWin: {
        title: 'Change language（Personal User）'
      },
      updateLanguageApi: {
        success: 'Update completed',
        failed: 'Update failed'
      }
    },
    panelSidebar: {
      homepage: 'Homepage',
      config: 'Group settings',
      membersByAll: 'Query members (All recorded)',
      membersByCount: 'Query members (number of speeches)',
      membersByTime: 'Member query (last speaking time)',
      membersByQuit: 'Check members (leaved in the last month)',
      top10: 'Enquiry speech Top10',
      daily: 'Query the volume of speeches in each period of the day'
    },
    quitList: {
      username: 'Username',
      nickname: 'Nickname',
      totalMessage: 'Total speech',
      joinTime: 'Join time',
      latestMsgTime: 'Final speech',
      quitTime: 'Quit time',
      operator: 'Executor',
      reason: 'Reason',
      blacklist: 'Blacklist',

      reasonItem: {
        reg: 'Regular',
        url: 'Link',
        self: 'Self',
        mention: 'Mention',
        markdown: 'Markdown',
        verify: 'Verify',
        timeout: 'Timeout',
        nickname: 'Nickname',
        via: 'Via Bot',
        warnVia: 'Via Warn',
        unknown: 'Unknown'
      },
      blocked: {
        yes: 'Yes',
        no: 'No'
      },

      totalLine: '{total} total',
      option: {
        ten: '10 items/page',
        twenty: '20 items/page',
        thirty: '30 items/page',
        fifty: '50 items/page',
        seventy: '70 items/page',
        hundred: '100 items/page'
      },

      reasonFilter: 'Reason Filter',
      filterWarning: 'Please select at least one option'
    }
  }
}
