import { provide, inject, createApp } from 'vue'
import Template from '../components/Dialog.vue'

const DialogSymbol = Symbol('some description')

let [dialogWrapper, dialogVM] = [null, null]
const _dialog = opts => {
  // 移除弹窗（loading 用）
  if (dialogWrapper !== null) {
    dialogVM.unmount(dialogWrapper)
    document.body.removeChild(dialogWrapper)
    dialogWrapper = dialogVM = null
  }
  if (opts.remove) {
    return
  }

  // 实例化弹窗
  dialogWrapper = document.createElement('div')
  document.body.appendChild(dialogWrapper)

  dialogVM = createApp(Template, {
    ...opts,
    rm: () => {
      dialogVM.unmount(dialogWrapper)
      document.body.removeChild(dialogWrapper)
      dialogWrapper = dialogVM = null
    }
  })

  dialogVM.mount(dialogWrapper)
}

export function provideDialog() {
  provide(DialogSymbol, _dialog)
}

export function useDialog() {
  const dialog = inject(DialogSymbol)
  if (!dialog) throw new Error('error')
  return dialog
}
