import store from '../store/index.js'
import { createI18n } from 'vue-i18n'

const defaultLanguage = 'zh-TW'
const userLang = store.getters.get('userLanguage')
const ipLang = localStorage.getItem('lang')
let localeLang = defaultLanguage
if (userLang !== '') {
  localeLang = userLang
} else if (ipLang !== null) {
  localeLang = ipLang
}

const i18n = createI18n({
  locale: localeLang.replace('-', '_'), // 默认显示的语言
  fallbackLocale: defaultLanguage.replace('-', '_'),
  messages: {
    en_US: require('./lang/en-US.js'),
    ja_JP: require('./lang/ja-JP.js'),
    // ru_RU: require('./lang/ru-RU.js'),
    zh_CN: require('./lang/zh-CN.js'),
    zh_HK: require('./lang/zh-HK.js'),
    zh_TW: require('./lang/zh-TW.js')
  }
})

export default i18n
