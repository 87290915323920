import { post } from './service/request.js'

// 登陆状态
export const statusApi = () => post('/status')

// 获取验证码
export const captchaApi = () => post('/captcha')

// 获取邮件
export const mailApi = (data, fn) => post('/mail', data, fn)

// 注册
export const registerApi = (data, fn) => post('/register', data)

// 修改注册信息
export const registerUpdateApi = (data, fn) => post('/register_update', data)

// 认证参数是否过期
export const authVerifyApi = (data, fn) => post('/auth_verify', data)

// 登陆
export const loginApi = (data, fn) => post('/login', data, fn)

// 自动登陆
export const autoLoginApi = (data, fn) => post('/auto_login', data, fn)

// 登出
export const logoutApi = () => post('/logout')

// 获取当前已登陆用户
export const loggedApi = (data, fn) => post('/logged', data, fn)

//  获取 bot 用户名
export const usernameApi = (data, fn) => post('/username', data, fn)

// 获取支持的语言
export const languagesApi = (data, fn) => post('/languages', data, fn)

// 更新用户语言
export const updateLanguageApi = (data, fn) => post('/update_language', data, fn)

// 获取管理的群组
export const groupsListApi = (data, fn) => post('/groups_list', data, fn)

// 获取群组配置
export const getGroupConfigApi = (data, fn) => post('/get_group_config', data, fn)

// 提交群组设置
export const setGroupConfigApi = (data, fn) => post('/set_group_config', data, fn)

// 获取群组所有记录到的人
export const membersByAllApi = (data, fn) => post('/members_by_all', data, fn)

// 根据发言数查询记录到的人
export const membersByCountApi = (data, fn) => post('/members_by_count', data, fn)

// 根据发言时间查询记录到的人
export const membersByTimeApi = (data, fn) => post('/members_by_time', data, fn)

// 查询最近一个月离开的人
export const membersByQuitApi = (data, fn) => post('/members_by_quit', data, fn)

// 踢人
export const kickMembersApi = (data, fn) => post('/kick_members', data, fn)

// 成员详细分析
export const memberDetailsApi = (data, fn) => post('/member_details', data, fn)

// 当日发言 top10
export const top10DayApi = (data, fn) => post('/top_10_day', data, fn)

// 总发言 top10
export const top10TotalApi = (data, fn) => post('/top_10_total', data, fn)

// 当日发言统计
export const groupDailyApi = (data, fn) => post('/group_daily', data, fn)
