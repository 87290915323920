import qs from 'qs'
import axios from 'axios'
import i18n from '../../i18n/index.js'
import router from '../../router/index.js'
import { toast } from '../../utils/alert.js'

// 环境切换
if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = 'http://api.bot.cc/v1'
} else if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = 'https://api.assistantbot.cc/v1'
}

let timeout

// 设置跨域 cookie
axios.defaults.withCredentials = true

// 请求超时时间
axios.defaults.timeout = 10000

// post 请求头
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencodedcharset=UTF-8'

// 请求拦截器
axios.interceptors.request.use(
  config => {
    // config.headers.Token = 'test'
    // config.data += `&timestamp=${new Date().getTime()}`

    // 超时刷新页面
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      if (/panel/.test(location.pathname)) location.reload()
    }, 1200 * 1000)

    return config
  },
  error => { return Promise.error(error) }
)

// 响应拦截器
axios.interceptors.response.use(
  response => {
    if (response.status === 200 && response.data.code < 10000) {
      if (response.config.url !== '/logged') {
        let str = ''
        if (response.data.code === 1001) {
          str = i18n.global.t('request.expired')
        } else if (response.data.code === 1002) {
          str = i18n.global.t('request.quit')
        } else if (response.data.code === 1003) {
          str = i18n.global.t('request.notAdmin')
        } else if (response.data.code === 1004) {
          str = i18n.global.t('request.maintain')
        }
        toast(str)
        router.push({ name: 'Login' })
      }
    }
    return Promise.resolve(response)
  },
  error => {
    toast(i18n.global.t('request.requestFailed'))
    if (error.response && error.response.status) {
      // switch (error.response.status) {
      //   case 404:
      //     break
      //   default:
      // }
      // router.push({ name: 'Login' })
      return Promise.reject(error.response)
    }
  }
)

/**
 * get 方法
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params, fn) {
  return new Promise((resolve, reject) => {
    axios.get(url, { params: params })
      .then(succ => { resolve(succ.data) })
      .catch(err => { reject(err.data) })
      .finally(() => fn)
  })
}

/**
 * post 方法
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params, fn) {
  return new Promise((resolve, reject) => {
    axios.post(url, qs.stringify(params))
      .then(succ => { resolve(succ.data) })
      .catch(err => { reject(err.data) })
      .finally(() => fn)
  })
}
