module.exports = {
  // common
  ok: '決定する',
  cancel: 'キャンセル',
  language: {
    en_US: 'English',
    ja_JP: '日本語',
    ru_RU: 'Pусский',
    zh_CN: '简体中文',
    zh_HK: '繁體中文（香港）',
    zh_TW: '繁體中文（台灣）'
  },

  // router
  router: {
    timeout: 'ログインタイムアウト'
  },

  // request
  request: {
    expired: 'ログインの有効期限が切れました',
    quit: 'Bot はもうグループにいません',
    notAdmin: '不正なログイン、あなたはグループ管理者ではありません',
    maintain: 'メンテナンス中のシステム',
    requestFailed: 'サーバーリクエストが失敗しました。しばらくしてからもう一度お試しください'
  },

  // views
  autoLogin: {
    tips: '認証中{dot}',

    autoLoginApi: {
      paramError: 'パラメータ異常',
      linkExpired: '認定リンクの有効期限が切れています'
    }
  },
  login: {
    inputEmail: 'メールアドレスを入力してください',
    inputPass: 'パスワードを入力してください',
    inputCaptcha: '検証コードを入力してください',
    captchaFailed: '検証コードの取得に失敗しました',
    register: 'アカウントを登録',
    update: '情報を変更する',
    login: 'ログイン',
    logging: 'ログイン中...',

    captchaApi: {
      captchaOverTimes: '本日取得した確認コードの数が上限に達しました。明日もう一度お試しください'
    },
    loginApi: {
      emailCanNotEmpty: 'メールアドレスを空にすることはできません',
      passCanNotEmpty: 'パスワードを空にすることはできません',
      captchaCanNotEmpty: '検証コードを入力してください',

      paramError: 'パラメータ異常',
      mailError: 'メールアドレスフォーマットエラー',
      captchaError: '検証コードエラー',
      loginFailedOverTimes: 'アカウントまたはパスワードが何度も間違っています。明日もう一度お試しください',
      mailOrPassError: 'メールアドレスまたはパスワードが正しくありません',
      busy: 'サーバーがビジーです。後でもう一度やり直してください'
    },

    win: {
      register: '登録リンクを取得するには、プライベートチャットロボット！<strong style="color: #ff3333;">!register</strong> を送信してください',
      update: '<p>プライベートチャットロボット！</p><strong style="color: #ff3333;">!update-pass</strong> パスワードをリセットするためのリンクを取得する<br><strong style="color: #ff3333;">!update-mail</strong> メールを置き換えるためのリンクを取得する'
    }
  },
  register: {
    title: 'アカウントを登録',
    inputEmail: 'メールアドレスを入力してください',
    inputCaptcha: '検証コードを入力してください',
    inputPass: 'パスワードを入力してください',
    inputConfirmPass: 'パスワードをもう一度入力してください',
    register: '登録済み',
    registering: '登録済み中...',

    captchaText: '検証コードを取得する',

    mailApi: {
      mailLimit: 'メールアドレスが間違っています。qq、163、126、yeah、sina、gmail、icloud、foxmail、outlook のみがサポートされています',

      paramError: 'パラメータ異常',
      linkExpired: 'リンクがタイムアウトしました。再取得してください',
      mailExist: 'メールアドレスボックスはすでに使用されています',
      overTimes: 'その日に10回送信されました。明日もう一度お試しください',
      busy: 'メールアドレスの送信頻度が高すぎる',
      failed: 'メールアドレスの送信に失敗しました',
      success: 'メールアドレスが送信されましたので、ご確認ください'
    },
    registerApi: {
      emailCanNotEmpty: 'メールアドレスを空にすることはできません',
      captchaCanNotEmpty: '検証コードを入力する必要があります',
      passCanNotEmpty: 'パスワードを空白にすることはできません',
      confirmPassCanNotEmpty: '繰り返しパスワードを空にすることはできません',
      passAndConfirmPassNotSame: '入力された2つのパスワードに一貫性がありません',

      paramError: 'パラメータ異常',
      linkExpired: 'リンクがタイムアウトしました。再取得してください',
      registered: 'すでにアカウントを登録しています',
      captchaError: '検証コードエラー',
      captchaGetMailAndConfirmMailNotSame: '確認コードを取得するためのメールアドレスは、実際に登録されているメールアドレスと同じではありません',
      mailExist: 'メールはすでに使用されています',
      passWeak: 'パスワードが仕様を満たしていません',
      success: '登録完了',
      failed: '登録失敗'
    }
  },
  registerUpdate: {
    titlePass: 'パスワードを再設定する',
    titleMail: 'メールを変更する',
    inputEmail: '新しいメールアドレスを入力してください',
    inputCaptcha: '検証コードを入力してください',
    inputPass: 'パスワードを入力してください',
    inputConfirmPass: 'パスワードをもう一度入力してください',
    update: '更新',
    updating: '更新中...',

    captchaText: '検証コードを取得する',

    mailApi: {
      mailLimit: 'メールアドレスが間違っています。qq、163、126、yeah、sina、gmail、icloud、foxmail、outlook のみがサポートされています',

      paramError: 'パラメータ異常',
      linkExpired: 'リンクがタイムアウトしました。再取得してください',
      mailExist: 'メールアドレスボックスはすでに使用されています',
      overTimes: 'その日に10回送信されました。明日もう一度お試しください',
      busy: 'メールアドレスの送信頻度が高すぎる',
      failed: 'メールアドレスの送信に失敗しました',
      success: 'メールアドレスが送信されましたので、ご確認ください'
    },
    registerUpdateApi: {
      emailCanNotEmpty: 'メールアドレスを空にすることはできません',
      captchaCanNotEmpty: '検証コードを入力する必要があります',
      passCanNotEmpty: 'パスワードを空白にすることはできません',
      confirmPassCanNotEmpty: '繰り返しパスワードを空にすることはできません',
      passAndConfirmPassNotSame: '入力された2つのパスワードに一貫性がありません',

      paramError: 'パラメータ異常',
      linkExpired: 'リンクがタイムアウトしました。再取得してください',
      unregistered: 'アカウントを登録していません',
      captchaError: '検証コードエラー',
      captchaGetMailAndConfirmMailNotSame: '確認コードを取得するためのメールアドレスは、実際に登録されているメールアドレスと同じではありません',
      mailExist: 'メールはすでに使用されています',
      exactlyTheSameAsBefore: '新しいメールを古いメールと同じにすることはできません',
      passWeak: 'パスワードが仕様を満たしていません',
      success: '更新完了',
      failed: '更新失敗'
    }
  },

  // views panel
  charts: {
    title: '記録されたグループの発言{title}：{total}',
    switch: 'スイッチスタイル',

    top10: {
      title: 'グループ発言 Top10',
      docPath: '/docs/#/?id=_6-查询发言-top10',
      placeholder1: '検索全て発言数 Top10',
      placeholder2: '検索今日の発言数 Top10'
    },
    daily: {
      title: '指定された日付時の発言数',
      docPath: '/docs/#/?id=_7-查询全天个时段发言量',
      placeholder1: '日付を選択してください'
    },

    top10TotalApi: {
      title: '合計'
    },
    top10DayApi: {
      title: '今日'
    },

    pieOption: {
      series: {
        name: 'ニックネーム'
      }
    },

    histOption: {
      yAxis: {
        name: '発言数',
        axisLabel: {
          formatter: '' // 空着
        }
      },
      top10: {
        xName: 'ニックネーム'
      },
      daily: {
        xName: '時間'
      }
    }
  },
  config: {
    on: 'オン',
    off: 'オフ',
    title: 'グループ経営',
    docTitle: '文書を表示する',
    docPath: '/docs/#/?id=_8-群组管理设置',
    overExceed: '文字長が制限を超えています',
    underAttack: {
      title: 'グループは攻撃を受けています',
      addBlacklist: 'ブラックリストに追加'
    },
    language: {
      title: '言語選択'
    },
    record: {
      title: '統計を話す',
      tips: '時間に正確な、スピーチの数に関するグループメンバーの詳細な統計'
    },
    serviceMessage: {
      title: 'Service Message',
      tips: 'グループに出入りするメンバーの Service Message を削除します'
    },
    challenge: {
      title: '新会員の回答確認',
      customizedIpt: {
        question: 'カスタム質問を入力してください',
        answer: 'カスタム質問への回答を入力してください',
        options: 'カスタム質問のオプションを入力してください'
      },
      customized: 'カスタムQ＆A',
      random: 'ランダムな足し算と引き算（100以内）',
      captcha: '画像確認コード',
      failedBlock: '確認に失敗すれば、 ユーザー禁止される',
      timeoutBlock: '検証タイムアウトすれば、 ユーザー禁止される',
      transfer: {
        tips: 'グループメンバーの認証プロセスはグループに表示されなくなります。認証が失敗すると、グループはメッセージを受信しなくなります。ウェルカムメッセージは、ロボットによってメンバーに直接送信されます。',
        text: '認証プロセスのロボットへの転送',
        guideText: 'ガイドのコピー\n\n例：新しいメンバーの参加を歓迎します。確認情報を入手するには、以下をクリックしてください',
        guideBtn: 'ジャンプボタンのコピー\n\n例：クリックして確認'
      },
      times: '{times} 回の検証を許可する',
      timeout: 'ユーザーは正常に検証されず、{timeout} 分以内に追い出されます',
      cooling: '禁止された後，もう一度グループに参加して、{cooling} 分間待ちます',
      noCooling: '禁止された後、すぐにグループを再追加できます'
    },
    authority: {
      title: 'グループへの新規参入者のアクセス',
      checkbox: {
        text: 'テキストメッセージを許可する',
        media: 'メディアメッセージの送信を許可する',
        polls: '投票を許可する',
        other: '他のメッセージの送信を許可する',
        wpp: 'Webプレビューメッセージの送信を許可する'
      },
      limitLift: '{hour} 時間後に解禁',
      alwaysLimit: '禁止を解除しない'
    },
    autoKick: {
      title: '自動削除オプション（ブラックリスト）',
      checkbox: {
        link: 'グループに入り、リンク付きに直接送信者',
        mention: 'グループに入り、*(Mention) でグループに直接送信者',
        markdown: 'グループに入り、Markdown でグループに直接送信者',
        media: 'グループに入り、メディアメッセージを直接送信者',
        forward: 'グループに参加して、他の人のメッセージを直接転送者',
        simplify: 'メッセージ通知を簡素化する',
        nicknameSilence: 'ニックネームの一致 - グループをサイレントに削除します',
        timeoutSilence: '検証がタイムアウトし - グループをサイレントに削除します',
        messageSilence: 'メッセージの一致 - グループをサイレントに削除します',
        alwaysMatchNickname: 'ニックネームの確認を常に有効にする',
        useSameReg: 'ニックネームの正規表現はメッセージの正規表現を使用します',
        defaultReg: 'システムプリセット正規表現を使用する'
      },
      customizedReg: {
        nicknamePlaceholder: 'ニックネームのカスタム正規表現、例：WeChat*Line',
        messagePlaceholder: 'メーセージのカスタム正規表現、例：WeChat*Line',
        notVerify: '新規参入者は {count} 回のスピーチ後にレビューしません',
        alwaysVerify: '常にレビュー中'
      }
    },
    dailyFunc: {
      title: '日常の機能',
      thead: {
        delete: '削除',
        kick: 'キック',
        warn: '警告 ({ count }回)',
        ignore: '無視',
        details: '説明'
      },
      tbody: {
        ignorePlaceholder: '例外 Inline Bot\nusername を使って',
        details: 'Inline Bot 関連メッセージ'
      }
    },
    autoReply: {
      title: '自動応答',
      placeholder: 'キーワード ~ 自動返信コンテンツ\nボタン名 - URLアドレス ** ボタン名 - URLアドレス\n\nキーワード ~ 自動返信コンテンツ\nボタン名 - URLアドレス ** ボタン名 - URLアドレス\n\n注意を払う：\n各行の中央に空白行があり、ボタンを設定できません\n各区切り文字の両側にスペースが必要です\n返信の内容に新しい行を開始しないでください\n\n* 区切り文字は、英語の入力方法で入力する必要があります\n* 区切り文字は、英語の入力方法で入力する必要があります\n* 区切り文字は、英語の入力方法で入力する必要があります'
    },
    welcome: {
      title: '新会員ウェルカムメッセージ設定',
      messagePlaceholder: '新会員へのウェルカムメッセージ',
      buttonPlaceholder: 'ボタン名 - URLアドレス ** ボタン名 - URLアドレス\n\n各セパレータの両側にスペースが必要です',
      autoDelete: '{minute} 分後にウェルカムメッセージを削除します',
      neverDelete: 'ウェルカムメッセージを削除しない'
    },
    creator: {
      user: '編集人： {user}',
      time: '編集時間： {time}'
    },
    submit: '提出する',

    setGroupConfigApi: {
      success: '設定完了しました',
      failed: '設定失敗しました',
      customized: 'カスタムQ＆Aには「質問、回答、オプション」を設定する必要があります',
      guide: '検証プロセスをボットに転送するには、「ガイドコピー、ジャンプボタンコピー」を設定する必要があります',
      paramError: 'パラメータ異常'
    }
  },
  member: {
    totalMessage: '話すの総数: {total}',
    joinTime: '参加時間: {time}',
    latestMsgTime: '最後に話す時間: {time}',

    head: {
      title: 'メンバーのスピーチの詳細な統計',
      placeholder: '月を選択してください'
    },
    weeks: {
      mon: '月曜日',
      tues: '火曜日',
      wed: '水曜日',
      thur: '木曜日',
      fri: '金曜日',
      sat: '土曜日',
      sun: '日曜日'
    },

    changeMonth: {
      error: '日付を空にすることはできません'
    },
    memberDetailsApi: {
      hour: {
        title: 'その月（時）の総発話量',
        xName: '時'
      },
      day: {
        title: 'その月（日）の総発話量',
        xName: '日'
      },
      week: {
        title: 'その月（曜日）の総発話量',
        xName: '曜日'
      }
    },
    histOption: {
      yAxis: {
        name: 'メッセージの数',
        axisLabel: {
          formatter: '' // 空着
        }
      }
    }
  },
  membersByAll: {
    head: {
      title: '記録されたすべてのグループメンバー',
      docPath: '/docs/#/?id=_2-查询成员（全部记录到的）'
    }
  },
  membersByCount: {
    head: {
      title: '指定された発言数検索メンバー',
      docPath: '/docs/#/?id=_3-查询成员（发言数量）',
      placeholder1: '起始发言量',
      placeholder2: '终止发言量'
    },

    setDate: {
      error: '開始数量と終了数量は空にすることはできません'
    }
  },
  membersByQuit: {
    head: {
      title: '1か月以内にグループを脱退したユーザー',
      docPath: '/docs/#/?id=_5-查询成员（最近一月离开）'
    }
  },
  membersByTime: {
    head: {
      title: '指定された時間検索メンバー',
      docPath: '/docs/#/?id=_4-查询成员（最后发言时间）',
      placeholder1: '始める時間',
      placeholder2: '止める時間'
    },

    setDate: {
      error: '開始数量と終了数量は空にすることはできません'
    }
  },
  panel: {
    title: '管理するグループ'
  },

  // 组件
  components: {
    noData: 'データが見つかりませんでした',
    groupsList: {
      classTitle: 'グループクラス',
      creator: '作成者',
      admin: '管理者',
      abnormal: '異常',
      totalMember: 'グループメンバーの総数',
      recordTotal: '記録されたスピーカーの数',
      joinTime: 'ロボットがグループに参加した時間'
    },
    homeNav: {
      homepage: 'ホームページ',
      flowChart: 'フローチャート',
      docs: 'ドキュメンテーション',
      donate: '寄付',
      login: 'ログイン'
    },
    membersList: {
      username: 'ユーザー名',
      nickname: 'ニックネーム',
      totalMessage: '総スピーチ',
      joinTime: '参加時間',
      latestMsgTime: '最終スピーチ時間',

      selectAll: 'すべて選択',
      totalLine: '合計 {total}',
      option: {
        ten: '10 アイテム/ページ',
        twenty: '20 アイテム/ページ',
        thirty: '30 アイテム/ページ',
        fifty: '50 アイテム/ページ',
        seventy: '70 アイテム/ページ',
        hundred: '100 アイテム/ページ'
      },
      addBlockList: 'ブラックリストに追加',
      notAddBlockList: 'ブラックリストに参加しないでください',
      kickSelectedMember: '選択したユーザーを削除する',

      kickMembers: {
        error: 'まず、削除するグループメンバーを選択します',
        ask: 'これらの {count} つのグループメンバーを削除してもよろしいですか'
      },
      kickMembersApi: {
        success: '正常に実行され、合計 {total}、<br><br> {success} アイテムが正常に実行され、<br>{failed} は実行に失敗しました',
        paramError: 'パラメータ異常'
      }
    },
    panelHead: {
      hoverTitle: '文書を表示する',
      search: '検索'
    },
    panelNav: {
      changeLanguage: '言語を変更',
      logout: 'サインアウト',

      changeLanguageWin: {
        title: '言語の変更（個人ユーザー）'
      },
      updateLanguageApi: {
        success: '更新成功',
        failed: '更新失敗'
      }
    },
    panelSidebar: {
      homepage: 'ホームページ',
      config: 'グループ設定',
      membersByAll: '検査メンバー（すべて記録）',
      membersByCount: '検査メンバー（発言数）',
      membersByTime: '検査メンバー（最後の話時間）',
      membersByQuit: '検査メンバー（先月脱退）',
      top10: '検査発言 Top10',
      daily: '検査一日中の発言数'
    },
    quitList: {
      username: 'ユーザー名',
      nickname: 'ニックネーム',
      totalMessage: '総スピーチ',
      joinTime: '参加時間',
      latestMsgTime: '最終発言',
      quitTime: '脱退時間',
      operator: '操作の人',
      reason: '原因',
      blacklist: 'ブラック',

      reasonItem: {
        reg: '正規表現',
        url: 'リンク',
        self: '自身',
        mention: '言及',
        markdown: 'MD',
        verify: '検証',
        timeout: 'タイムアウト',
        nickname: 'ニックネーム',
        via: '経由 Bot',
        warnVia: '経由 Warn',
        unknown: 'わからない'
      },
      blocked: {
        yes: 'はい',
        no: 'いいえ'
      },

      totalLine: '合計 {total}',
      option: {
        ten: '10 アイテム/ページ',
        twenty: '20 アイテム/ページ',
        thirty: '30 アイテム/ページ',
        fifty: '50 アイテム/ページ',
        seventy: '70 アイテム/ページ',
        hundred: '100 アイテム/ページ'
      },

      reasonFilter: '理由分類',
      filterWarning: '少なくとも1つのオプションを選択してください'
    }
  }
}
