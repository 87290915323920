<template>
  <router-view/>
</template>

<script>
import { useStore } from 'vuex'
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { provideDialog } from './utils/dialog.js'
import { usernameApi, languagesApi } from './api/index.js'
import Axios from 'axios'

export default {
  setup() {
    provideDialog() // 初始化提供 dialog 能力

    const { locale } = useI18n()
    const Store = useStore()

    onMounted(() => {
      loadArea()
      username()
      language()
      loadImg()
      author()
    })

    /**
     * 查询 IP 归属地并保存语言信息到 session storage
     */
    const loadArea = () => {
      Axios.get(`${document.location.origin}/ip`).then(succ => {
        if (succ === undefined) return
        let lang = ''
        switch (succ.data.countryCode) {
          case 'JP': {
            lang = 'ja-JP'
            break
          }
          // case 'RU': {
          //   lang = 'ru-RU'
          //   break
          // }
          case 'CN': {
            lang = 'zh-CN'
            break
          }
          case 'HK': {
            lang = 'zh-HK'
            break
          }
          case 'TW': {
            lang = 'zh-TW'
            break
          }
        }

        localStorage.setItem('lang', lang)
        if (Store.getters.get('userLanguage') === '') {
          locale.value = lang.replace('-', '_')
        }
      })
    }

    /**
     * 获取 bot 用户名
     */
    const username = () => {
      usernameApi().then(succ => {
        if (succ.code === 10000) {
          Store.commit('set', { username: succ.data.username })
          conso(succ.data.username)
        }
      })
    }

    /**
     * 获取支持的语言
     */
    const language = () => {
      languagesApi().then(succ => {
        if (succ.code === 10000) {
          Store.commit('set', { language: succ.data.list })
        }
      })
    }

    /**
     * loadImg
     */
    const loadImg = () => {
      [
        '/img/loading-auto-login.svg',
        '/img/loading-captcha.svg',
        '/img/loading-cat.svg',
        '/img/loading-dot.svg'
      ].forEach(el => {
        new Image().src = el
      })
    }

    /**
     * author
     */
    const author = () => {
      const author = document.createElement('a')
      // author.setAttribute('target', '_blank')
      author.setAttribute('href', 'javascript:void(0)')
      author.setAttribute('style', 'color: #e54d26;position: fixed;right: 30px;bottom: 15px;z-index: 1;border-bottom: 2px double #e54d26;')
      author.innerHTML = 'Designed by KA'
      document.body.appendChild(author)
    }

    /**
     * 艺术 console
     */
    const conso = (username) => {
      const font = [
        ['       d8888                   d8b          888                      888         888888b.            888   '],
        ['      d88888                   Y8P          888                      888         888  "88b           888   '],
        ['     d88P888                                888                      888         888  .88P           888   '],
        ['    d88P 888 .d8888b  .d8888b  888 .d8888b  888888  8888b.  88888b.  888888      8888888K.   .d88b.  888888'],
        ['   d88P  888 88K      88K      888 88K      888        "88b 888 "88b 888         888  "Y88b d88""88b 888   '],
        ['  d88P   888 "Y8888b. "Y8888b. 888 "Y8888b. 888    .d888888 888  888 888         888    888 888  888 888   '],
        [' d8888888888      X88      X88 888      X88 Y88b.  888  888 888  888 Y88b.       888   d88P Y88..88P Y88b. '],
        ['d88P     888  88888P"  88888P" 888  88888P"  "Y888 "Y888888 888  888  "Y888      8888888P"   "Y88P"   "Y888'],
        [],
        [`                                                                              https://t.me/${username}`]
      ]
      const encode = btoa(encodeURI(JSON.stringify(font)))
      if (!(window.ActiveXObject || 'ActiveXObject' in window)) {
        const log = ['%c']
        const obj = JSON.parse(decodeURI(atob(encode)))
        obj.forEach(function(data) {
          const arr = data[0]
          log.push(arr)
        })
        const str = [log.join('\n')].concat(['color:#e54d26'])
        console.log.apply(console, str)
      }
    }
  }
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}
html, body, #app, .app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #eaeaea;
}
html, body {
  color: #222;
  font-size: 16px;
  transform: translate3d(0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Arial,PingFang SC,Hiragino Sans GB,Microsoft YaHei,sans-serif;
}
#app {
  cursor: url('./assets/img/cursor.png') 8 0, auto;
  .app {
    > div[class$="-content"] {
      overflow: auto;
      position: absolute;
      right: 0;
      bottom: 0;
      > div[class$="-content-in"] {
        width: 100%;
        min-width: 1100px;
        padding-left: 30px;
        padding-right: 30px;
        margin: 0 auto;
      }
      @media screen and (max-width: 1870px) {
        > div[class$="-content-in"] { max-width: 1494px; }
      }
      @media screen and (max-width: 1654px) {
        > div[class$="-content-in"] { max-width: 1278px; }
      }
      @media screen and (max-width: 1438px) {
        > div[class$="-content-in"] { max-width: 1079px; }
      }
    }
    .home-content {
      top: 90px;
      left: 0;
      padding-top: 40px;
      padding-bottom: 40px;
    }
    .panel-content {
      top: 60px;
      left: 280px;
      padding: 30px 20px;
      transition: left 0.2s;
      transform: translate3d(0, 0, 0);
      > div[class$="-content-in"] {
        padding-top: 20px;
        padding-bottom: 20px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 2px 1px 10px 1px rgba(0, 0, 0, 0.2);
      }
    }
  }
}
img {
  display: block;
  width: 100%;
  -webkit-user-drag: none;
}
ul {
  list-style: none;
}
a {
  color: #222;
  text-decoration: none;
}
i, em {
  font-style: normal;
}
input, textarea {
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  outline: none;
  font-size: 15px;
  word-break: break-all;
  background: transparent;
}
.input-indent {
  padding: 0 10px;
}
input[type="submit"], input[type="button"] {
  cursor: pointer;
}
textarea {
  resize: none;
}
label {
  cursor: pointer;
}
label::before {
  vertical-align: text-top;
}
table {
  border-style: hidden;
  border-collapse: collapse;
  box-shadow: 0 0 0 1px #888;
  th, td {
    border: 1px solid #888;
  }
  th:last-child, td:last-child {
    border-right: none;
  }
}
hr {
  height: 1px;
  border: none;
  background: #888;
}

// 无数据
.no-data {
  font-size: 35px;
  font-weight: bold;
  padding: 200px 0;
  text-align: center;
}

// range 美化
input[type="range"] {
  width: 100%;
  height: 6px;
  outline: none;
  margin: 8px 0;
  border-radius: 3px;
  background-color: #60b7ff;
  -webkit-appearance: none !important;
}
input[type="range"]::-webkit-slider-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #60b7ff;
  border: 4px solid #fff;
  -webkit-appearance: none;
}

// 单选 radio 美化
input[type="radio"] {
  width: 0px;
  height: 0px;
  position: absolute;
  opacity: 0;
}
input[type="radio"] + label:before {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1em;
  opacity: 0.8;
  margin-right: 0.5em;
  transition: all 0.25s ease;
  background: url('./assets/img/radio-unchecked.svg') no-repeat center center / cover;
}
input[type="radio"]:checked + label:before {
  background: url('./assets/img/radio-checked.svg') no-repeat center center / cover;
}
// input[type="radio"]:focus + label:before {
//   outline: none;
//   border-color: #3197EE;
// }
input[type="radio"]:disabled + label:before {
  opacity: 0.3;
  background: url('./assets/img/radio-checked.svg') no-repeat center center / cover;
}
input[type="radio"] + label:empty {
  justify-content: space-around;
}
input[type="radio"] + label:empty:before {
  margin-right: 0;
}

// 多选 checkbox 美化
input[type="checkbox"] {
  width: 0px;
  height: 0px;
  opacity: 0;
  position: absolute;
}
input[type="checkbox"] + label {
  display: table;
  text-indent: 0.5em;
  white-space: nowrap;
}
input[type="checkbox"] + label:before {
  content: '';
  display: table-cell;
  opacity: 0.8;
  padding: 0.5em;
  transition: all 250ms ease;
  background: url('./assets/img/checkbox-unchecked.svg') no-repeat center center / contain;
}
input[type="checkbox"]:checked + label:before {
  background: url('./assets/img/checkbox-checked.svg') no-repeat center center / contain;
}
input[type="checkbox"]:indeterminate + label:before {
  background: url('./assets/img/checkbox-indeterminate.svg') no-repeat center center / contain;
}
// input[type="checkbox"]:focus + label:before {
//   outline: none;
//   border-color: #3197EE;
// }
input[type="checkbox"]:disabled + label:before {
  opacity: 0.3;
  background: url('./assets/img/checkbox-checked.svg') no-repeat center center / contain;
}
input[type="checkbox"] + label:empty {
  justify-content: space-around;
}
input[type="checkbox"] + label:empty:before {
  margin-right: 0;
}

// 滑动选择按钮
.switch-btn {
  position: relative;
  width: 65px;
  height: 26px;
  flex-shrink: 0;
  margin-left: 50px;
  input[type="checkbox"] {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
  }
  input[type="checkbox"]:checked ~ div {
    background: #60b7ff;
    box-shadow: 0px 0px 2px #60b7ff inset;
  }
  input[type="checkbox"]:checked ~ div .circle {
    left: 42px;
  }
  input[type="checkbox"]:checked ~ div .on {
    opacity: 1;
    left: 6px;
  }
  input[type="checkbox"]:checked ~ div .off {
    opacity: 0.5;
    right: -42px;
  }
  div {
    width: 100%;
    height: 100%;
    border-radius: 50px;
    overflow: hidden;
    position: relative;
    background: #f8456e;
    transition: all 0.2s ease-in-out;
    box-shadow: 0px 0px 2px #f8456e inset;
    .circle {
      display: inline-block;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 3px;
      left: 3px;
      z-index: 1;
      border-radius: 50%;
      background: #fff;
      box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2),0 3px 4px 0 rgba(0,0,0,0.1);
      transition: all 0.2s linear;
    }
    i {
      color: #fff;
      width: 35px;
      line-height: 26px;
      text-align: center;
      position: absolute;
      top: 0;
      transition: all 0.2s linear;
    }
    .on {
      opacity: 0.5;
      left: -42px;
    }
    .off {
      opacity: 1;
      right: 6px;
    }
  }
}
</style>
